
ion-tab-button.tab-selected {
  color: var(--ion-color-shiawasedo)!important;
  .custom-icon {
    display: none;
    color: var(--ion-color-shiawasedo)!important;
    &.active {
      display: block;
    }
  }
}
ion-tab-bar {
  contain: none;
  z-index: 1;
}
ion-tab-bar ion-tab-button:nth-child(2) {
  --background: none;
  height: calc(40px + 100%);
  margin-top: -45px;
  color: var(--ion-color-gold)!important;
}
ion-tab-button {
  position: relative;
  .custom-label {
    position: absolute;
    bottom: 0;
  }
  &.tab-selected .custom-label {
    color: var(--ion-color-shiawasedo)!important;
  }
}
.icon-wrapper {
  display: inline-block;
  padding: 5px;
  border-top: 1px solid #999;
  background: #fff;
  z-index: -1;
  position: absolute;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom: 0;
}
.custom-icon {
  font-size: 60px;
  position: relative;
  overflow: hidden;
  display: block;
  &.active {
    display: none;
  }
}
.icon-wrapper {
  pointer-events: all;
}
.tab-disabled {
  opacity: 1;
}
